<!--
 * @Author: HZH
 * @Date: 2021-09-16 14:16:01
 * @LastEditors: HZH
 * @LastEditTime: 2021-09-18 09:37:26
 * @Description: 
-->
<template>
	<div>
		<div class="home">
			<Head></Head>
			<div class="content">
				<div class="wrapper">
					<div class="swipe" style="margin-top: 30px;">
						<!-- {{swipe}} -->
						<el-carousel height="350px">
							<el-carousel-item v-for="item in swipe" :key="item.id">
								<!-- <h3 class="small">{{ item }}</h3> -->
								<img style="width: 100%;" :src="item.url" alt="">
							</el-carousel-item>
						</el-carousel>
					</div>
					<div class="product_title">产品展示</div>
					<div class="plates">
						<div class="plate" v-for="item in titleData" :key="item.id">
							<div>
								<img class="plate_img" :src="item.url" alt="">
								<div class="plate_title">{{item.title}}</div>
							</div>
						</div>
					</div>
					<div class="product_title">云播助手APP功能说明</div>
					<div class="plates">
						<div>
							1、功能展示展示<br>
							
							　　云播助手，是一款手机辅助直播的工具。用户在第三方平台进行直播时，可通过述播进行音乐播放或者音频录制;可以更好的和直播间的用户互动<br>
							
							　　实现沉浸式直播体验。述播提供全行业的直播间讲解话术，可供用户使用，无需要自己撰写直播脚本。<br>
							
							　　2、公司介绍<br>
							
							　　云播助手基于实况直播且做到智能AI互动直播回复、脱离直播经验缺乏、主播成本高的限制、同时接入ChtaGPT智能撰写话术技术、利用接口技术做到直播风控。<br>
							3、公司理念<br>
							
							　　一家集品牌供应链，网红孵化和抖音直播、短视频运营培训教学为一体的新媒体公司，致力于孵化网红达人，优质短视频制作，更是品牌运营的策划师，核心团队具有丰富的互联网运营，短视频，直播经验，公司当前已储备大v网红，各级别主播，拥有上万人同城探店达人矩阵团，致力于为商家商家提供多元化的产品销售手段，精准化品牌宣传。<br>
							
							　　订单列表筛选功能强大，订单状态准确清晰，订单详情更完整记录电商平台系统订单所有相关信息流程。<br>
							
							4、核心功能<br>
							
							　　接入阿里云AI真人语言、自动生成口播语音、适用于不限中小企业类目直播、突破传统营销、利用核心技术自动解说实景直播<br>
							
							在直接采用电子支付手段将可以节省交易费用。但电子支付将需要更为可靠的信息传输安全性，以防止欺骗、窃听、冒用等非法行为
						</div>
					</div>
					<div class="footer">
						<Foot></Foot>
					</div>
				</div>
			</div>
			
		</div>
	</div>
</template>

<script>
	import {
		get
	} from "../utils/request";
	import Head from './components/Head.vue'
	import Foot from './components/Foot.vue'
	export default {
		// 使用注册组件
		components: {
			// 1.引入所需组件

			// 2.注册组件
			Head,
			Foot
		},
		data() {
			return {
				// message: "hello",
				swipe: [
					{
						"id":1,
						"url":"https://quanchengtandian.oss-cn-beijing.aliyuncs.com/uploads/20230818/c4e4852cb4fce7d5d298b2ded2219252.jpg",
					},
					
				],
				titleData: [
					{
						"id":1,
						"url":"https://quanchengtandian.oss-cn-beijing.aliyuncs.com/uploads/20230818/af0ec8dfb4e633f9a32ce934cb087bf8.jpg",
						"title":"产品1"
					},
					
					{
						"id":1,
						"url":"https://quanchengtandian.oss-cn-beijing.aliyuncs.com/uploads/20230818/c4e4852cb4fce7d5d298b2ded2219252.jpg",
						"title":"产品2"
					},
					
				]
			};
		},
		methods: {
			// 引入封装好的工具类
			getSwipeData() {
				get("/index/carousel/findAll").then((res) => {
					this.swipe = res.data;
				});
			},
			gettitleData() {
				get('/index/article/findCategoryArticles').then(res => {
					this.titleData = res.data;
				})
			}
		},
		created() {
			// this.getSwipeData();
			// this.gettitleData()
		},
	};
</script>

<style lang="scss" scoped>
	.content {
		.wrapper {
			width: 85%;
			.plates {
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				.plate {
					display: flex;
					width: 25%;
					padding: 10px;
					box-sizing: border-box;
					position: relative;
					margin-bottom: 30px;
					.plate_img{
						display: block;
						width: 100%;
						height: 100%;
						border-top-left-radius: 20px;
						border-top-right-radius: 20px;
					}
					.plate_title{
						margin-top: 15px;
					}
				}
			}
		}
		.product_title{
			font-size: 30px;
			font-weight: 600;
			margin: 20px;
		}	
	}
	
	.footer {
		
		margin-top: 40px;
		height: 200px;
		// background-color: #528c38;
	}
</style>